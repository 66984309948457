//-----------------Property Profile Page-----------------//

// Show More/Less property description event button
$(".read-more-desc").click(function() {
    $(".property-description .description").toggleClass('hiddenDesc');
    $(this).find('span').toggle();
});


$(document).ready(function () {

    // Property Profile Page - check if the property description is more than 3 lines so we add the show more button
    if($(".property-description .description").height()>40) {
        $(".property-description .description").addClass('hiddenDesc');
    }else{
        $(".read-more-desc").hide();
    }

    setTimeout(function(){
        $('#loader').remove();
    }, 1000);

});


