/* get the height of the featured property and set it to the container of "just added" properties */
function getHeight (){
    let viewMoreBtn = $('.viewMoreBtn').height();
    let propertyBoxHeight = $('.homepage-featured-slider').height();
    // console.log(viewMoreBtn);
    // console.log(propertyBoxHeight);
    $(".propertyBoxHeight").height(propertyBoxHeight-6-viewMoreBtn);
}

$(window).on('resize', function () {
    getHeight();
});

$(window).on('load', function () {
    getHeight();
});

$(function (){

    let selects= document.querySelectorAll('.choices-select');

    if (selects) {

        selects.forEach(function (e) {

            if ($(e).hasClass('searchable')) {

                new Choices(e, {
                    allowHTML: false,
                    shouldSort: false,
                    searchEnabled: true,
                });

            } else {

                new Choices(e, {
                    allowHTML: false,
                    shouldSort: false,
                    searchEnabled: false,
                });

            }

        });
    }

});

