// Navbar menu enable hover dropdowns
document.addEventListener("DOMContentLoaded", function () {
        navbarHover(true);
});

// window.addEventListener("resize", function () {
//     if (window.innerWidth > 992) {
//         navbarHover(true);
//     }else {
//         navbarHover(false);
//     }
// });

// On hover show dropdown
let onHover = function () {

    let el_link = this.querySelector('a[data-bs-toggle]');
    let el_main_menu = document.getElementById('main-menu');
    if (window.screen.width > 992 ) {
        el_main_menu.classList.add('bg-white');
    }

    if (el_link != null) {
        let nextEl = el_link.nextElementSibling;
        el_link.classList.add('show');

        if(nextEl !== null) {
            nextEl.classList.add('show');
        }
    }

};

// On mouse leave hide dropdown
let onMouseLeave = function () {
    let el_link = this.querySelector('a[data-bs-toggle]');
    let el_main_menu = document.getElementById('main-menu');
    el_main_menu.classList.remove('bg-white');


    if (el_link != null) {
        let nextEl = el_link.nextElementSibling;
        el_link.classList.remove('show');

        if(nextEl !== null) {
            nextEl.classList.remove('show');
        }
    }


}

function navbarHover(x)
{
    let elements = document.querySelectorAll('.navbar .nav-item.dropdown');

    if (x) {
        elements.forEach(function (everyitem) {
            everyitem.addEventListener('mouseover', onHover, true);
            everyitem.addEventListener('mouseleave', onMouseLeave, true);
        });
    }else {
        elements.forEach(function (everyitem) {
            everyitem.removeEventListener('mouseover', onHover, true);
            everyitem.removeEventListener('mouseleave', onMouseLeave, true);
        });
    }
}



// Header shrink while page scroll
adjustHeader();
doSticky();
// placedDashboard();
$(window).on('scroll', function () {
    adjustHeader();
    doSticky();
    // placedDashboard();
});

// Header shrink while page resize
$(window).on('resize', function () {
    adjustHeader();
    doSticky();
});

function adjustHeader()
{

    var windowWidth = $(window).width();
    var blackLogo = "/assets/images/black-logo.svg";
    var whiteLogo = "/assets/images/white-logo.svg";

    if($('.main-menu').hasClass('homepage')) {

        if (windowWidth > 1) {
            if ($(document).scrollTop() >= 100) {
                if ($('.header-shrink').length < 1) {
                    $('.sticky-header').addClass('header-shrink');
                }
                if ($('.do-sticky').length < 1) {
                    $('#logo').attr('src', blackLogo);
                }
            } else {
                if ($('.no-transparent-header').length) {
                    $('.no-transparent-header').addClass('header-shrink');
                    $('#logo').attr('src', blackLogo);

                } else {
                    $('.sticky-header').removeClass('header-shrink');
                    if ($('.do-sticky').length < 1 && $('.fixed-header').length == 0 && $('.fixed-header2').length == 0) {
                        $('#logo').attr('src', whiteLogo);
                        $('.properties-header #logo').attr('src', blackLogo);
                    } else {
                        $('.properties-header #logo').attr('src', whiteLogo);
                        $('#logo').attr('src', blackLogo);
                    }
                }
            }
        } else {
            $('#logo').attr('src', blackLogo);
        }

        if ($('.sticky-header').hasClass('bg-white')) {
            $('#logo').attr('src', blackLogo);
        }
    }

    if (windowWidth < 991) {
        $('#logo').attr('src', whiteLogo);
    }else{
        if(!$('.main-menu').hasClass('homepage')) {
            $('#logo').attr('src', blackLogo);
        }
    }
}

function doSticky()
{
    if ($(document).scrollTop() > 40) {
        $('.do-sticky').addClass('sticky-header');
        //$('.do-sticky').addClass('header-shrink');
    }
    else {
        $('.do-sticky').removeClass('sticky-header');
        //$('.do-sticky').removeClass('header-shrink');
    }
}
