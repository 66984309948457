let singleOverviewSlider = $('.sliderHeroImages');
// Single Overview slider
singleOverviewSlider.slick({
    autoplay:true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    adaptiveHeight:true,
    nextArrow: $('.slider-next'),
    prevArrow: $('.slider-prev'),
    mobileFirst     : true,
    arrows: true,
    fade: true,
    dots: true,
    autoplaySpeed: 5000,

});
// singleOverviewSlider.slickLightbox({
//     itemSelector: 'a'
// });

$('#citiesSlider').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    centerMode: false,
    adaptiveWeight: true,
    arrows: true,
    nextArrow: $('.cities-slider .slider-next-btn'),
    prevArrow: $('.cities-slider .slider-prev-btn'),
    centerPadding: '50px',
    cssEase: 'linear',
    dots: true,
    responsive: [
        {
            breakpoint: 980,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 540,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});


$('.luxuryPropertiesSlider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    adaptiveWeight: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: $('.luxury-properties-homepage .slider-next'),
    prevArrow: $('.luxury-properties-homepage .slider-prev'),
    arrows: true,
    cssEase: 'linear',
    centerMode: true,
    centerPadding: '80px',
    dots: true,
    responsive:[
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerPadding: '10px',
            }
        },
    ]
});

$('.homepage-featured-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    adaptiveWeight: true,
    // nextArrow: $('.featured-properties .slider-next'),
    // prevArrow: $('.featured-properties .slider-prev'),
    nextArrow: $('.box-slider-next'),
    prevArrow: $('.box-slider-prev'),
    arrows: true,
    cssEase: 'linear',
    dots: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
    ]
});

$('.residencesSlider').each(function(index) {
    $(this).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: true,
        adaptiveHeight: true,
        nextArrow: $('.slider-next-'+index),
        prevArrow: $('.slider-prev-'+index),
        mobileFirst: true,
        arrows: true,
        fade: true,
        dots: false,
        autoplaySpeed: 5000,

    });

    $(this).slickLightbox({
        src: 'src',
        itemSelector: 'a',
        navigateByKeyboard:true,
        lazy: true,
        arrows: true,
        dots: true,
        background:'rgba(0,0,0,.8)',
        closeOnEscape:true,
        closeOnBackdropClick:true,

    });

    $(this).slickLightbox().on({
        'show.slickLightbox': function () {
            $('.slick-arrow').html("");
        },
    });

});


// Property sSINGLE page photos slider
$('.propertyImgSliderfor').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    infinite: true,
    // asNavFor: '.propertyImgSliderNav',
    autoplay: true,
    // adaptiveHeight: true,
    prevArrow: '<button type="button" class="slider-prev property-img-prev delfi-btn-overlay"></button>',
    nextArrow: '<button type="button" class="slider-next property-img-next delfi-btn-overlay"></button>',
});

// $('.propertyImgSliderNav').slick({
//     slidesToShow: $('#slides').val()<5?$('#slides').val():5,
//     slidesToScroll: 1,
//     asNavFor: '.propertyImgSliderfor',
//     dots: false,
//     infinite: true,
//     centerMode: $('#slides').val()<5?false:true,
//     focusOnSelect: true,
//     // adaptiveHeight: true,
//     // arrows: $('#slides').val()<5?false:true,
//     nextArrow: $('.property-img-next'),
//     prevArrow: $('.property-img-prev'),
//     responsive: [
//         {
//             breakpoint: 992,
//             settings: {
//                 // arrows: $('#slides').val()<5?false:true,
//                 centerPadding: '40px',
//                 slidesToShow: $('#slides').val()<5?$('#slides').val():3
//             }
//         },
//         {
//             breakpoint: 580,
//             settings: {
//                 // arrows: $('#slides').val()<5?false:true,
//                 centerPadding: '20px',
//                 slidesToShow: $('#slides').val()<5?$('#slides').val()-1:3
//             }
//         },
//         // {
//         //     breakpoint: 768,
//         //     settings: {
//         //         arrows: false,
//         //         centerMode: true,
//         //         centerPadding: '40px',
//         //         slidesToShow: 1
//         //     }
//         // }
//     ]
// });

$('.propertyImgSliderfor').slickLightbox({
    src: 'data-image',
    itemSelector: '.slide .image',
    navigateByKeyboard:true,
    lazy: true,
    arrows: true,
    background:'rgba(0,0,0,.8)',
    closeOnEscape:true,
    closeOnBackdropClick:true,
});

$('.propertyImgSliderfor').slickLightbox().on({
    'show.slickLightbox': function () {
        $('.slick-arrow').addClass("delfi-btn-overlay");
        $('.slick-arrow').text("");
    },
});

$('.property-map-buttons-sliders').slick({
    slidesToShow: 4,
    adaptiveHeight: false,
    infinite: false,
    autoplay: false,
    arrows: true,
    nextArrow: $('.property-map-buttons-sliders-buttons .slider-next'),
    prevArrow: $('.property-map-buttons-sliders-buttons .slider-prev'),
    dots: true,
    // variableWidth: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        // {
        //     breakpoint: 990,
        //     settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //     }
        // }
    ]
});
